var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Watch, Prop, Emit } from "vue-property-decorator";
import { ImportVisitFollowRecordCaseService } from "~/services/dataimp-service/import-visit-follow-record-case.service";
var VisitFollowImportDetails = /** @class */ (function (_super) {
    __extends(VisitFollowImportDetails, _super);
    function VisitFollowImportDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.currentCase = {};
        _this.dialog = {
            certificate: false,
            record: false,
            video: false
        };
        _this.searchModel = {
            operBatchNumber: "",
            batchNumber: "",
            personalName: "",
            account: "" // 账号
        };
        _this.dataSet = [];
        return _this;
    }
    /**
     * 批次号变化更新Model并刷新数据列
     */
    VisitFollowImportDetails.prototype.updateCaseDetail = function () {
        this.searchModel.operBatchNumber = this.operBatchNumber;
        this.refreshData();
    };
    /**
     * 刷新数据列
     */
    VisitFollowImportDetails.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.importVisitFollowRecordCaseService.getCollectionList(this.searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) { return (_this.dataSet = data); });
    };
    /**
     * 搜索条件重置
     */
    VisitFollowImportDetails.prototype.reset = function () {
        this.searchModel = {
            operBatchNumber: "",
            batchNumber: "",
            personalName: "",
            account: "" // 人民币清算账号
        };
    };
    /**
     * 返回
     */
    VisitFollowImportDetails.prototype.onBackCaseList = function () { };
    /**
   *查看看凭证
   */
    VisitFollowImportDetails.prototype.certificateDetails = function (follow, type) {
        this.currentCase = follow;
        if (type === 1) {
            this.dialog.certificate = true;
        }
        else if (type === 2) {
            this.dialog.record = true;
        }
        else {
            this.dialog.video = true;
        }
    };
    __decorate([
        Dependencies(PageService)
    ], VisitFollowImportDetails.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], VisitFollowImportDetails.prototype, "sortService", void 0);
    __decorate([
        Dependencies(ImportVisitFollowRecordCaseService)
    ], VisitFollowImportDetails.prototype, "importVisitFollowRecordCaseService", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], VisitFollowImportDetails.prototype, "operBatchNumber", void 0);
    __decorate([
        Watch("operBatchNumber", { immediate: true })
    ], VisitFollowImportDetails.prototype, "updateCaseDetail", null);
    __decorate([
        Emit("onBackCaseList")
    ], VisitFollowImportDetails.prototype, "onBackCaseList", null);
    VisitFollowImportDetails = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm
            }
        })
    ], VisitFollowImportDetails);
    return VisitFollowImportDetails;
}(Vue));
export default VisitFollowImportDetails;
