var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import ImportVisitFollowCase from "~/components/case-manage/visit-follow-record-import/import-visit-follow-case.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { ImportVisitFollowRecordCaseService } from "~/services/dataimp-service/import-visit-follow-record-case.service";
import { ImportOthersDataExcelRecordService } from "~/services/dataimp-service/import-others-data-excel-record.service";
var OutCollectionImport = /** @class */ (function (_super) {
    __extends(OutCollectionImport, _super);
    function OutCollectionImport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.searchModel = {
            batchNumber: "",
            importDataExcelStatus: "",
        };
        _this.dialog = {
            importCase: false,
        };
        _this.dataSet = [];
        _this.seqNo = "";
        _this.loading = {
            state: false,
        };
        return _this;
    }
    /**
     * 查看详情
     */
    OutCollectionImport.prototype.viewDetail = function (rowData) { };
    OutCollectionImport.prototype.mounted = function () {
        var _this = this;
        // 新增排序方式
        this.caseImportSortService.update("operatorTime", "descending");
        this.refreshData();
        // 收到消息通知时更新未读消息
        this.$reminder.addMessageListener().subscribe(function (data) {
            if (["IMPORT_EXCEL_MSG", "IMPORT_CONFIRMED_MSG"].includes(data.messageType)) {
                _this.refreshData();
            }
        });
    };
    OutCollectionImport.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.importVisitFollowRecordCase.getImportVisitCollectionRecords(this.searchModel, this.pageService, this.caseImportSortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 错误信息下载
     */
    OutCollectionImport.prototype.errorTipsDownload = function (row) {
        var _this = this;
        this.uploadFileService.getFileStreamById(row.resultUrl).subscribe(function () { return _this.$message.success("下载成功"); });
    };
    /**
     * 外访调记撤销
     */
    OutCollectionImport.prototype.revertCick = function (row) {
        var _this = this;
        this.$confirm("确认要对此批次进行撤销", "提示").then(function () {
            _this.importOthersDataExcelRecordService.revokeOthersExcelImp(row.id).subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        });
    };
    /**
     * 取消导入
     */
    OutCollectionImport.prototype.cancelImport = function (row) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u53D6\u6D88\u5BFC\u5165" + row.batchNumber + "\u6279\u6B21\u7684\u8BB0\u5F55\u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.importVisitFollowRecordCase.cancelImpVisitRecord(row.id).subscribe(function (data) {
                _this.$message.success("取消导入成功!");
                _this.refreshData();
            });
        })
            .catch(function (ex) { });
    };
    /**
     * 确认导入
     */
    OutCollectionImport.prototype.confirmImport = function (row) {
        var _this = this;
        this.$confirm("\u60A8\u786E\u5B9A\u8981\u5BFC\u5165" + row.batchNumber + "\u6279\u6B21\u7684\u8BB0\u5F55\u5417\uFF1F", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            _this.importVisitFollowRecordCase.confirmImpDate(row.id).subscribe(function (data) {
                _this.$message.success("正在确认请稍后!");
                _this.refreshData();
            });
        });
    };
    OutCollectionImport.prototype.isShowOperate = function (rowData) {
        return rowData.importDataExcelStatus === "IMPORT_SUCCESSFULLY" || rowData.importDataExcelStatus === "UN_CONFIRMED";
    };
    __decorate([
        Dependencies(PageService)
    ], OutCollectionImport.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], OutCollectionImport.prototype, "caseImportSortService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], OutCollectionImport.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(ImportVisitFollowRecordCaseService)
    ], OutCollectionImport.prototype, "importVisitFollowRecordCase", void 0);
    __decorate([
        Dependencies(ImportOthersDataExcelRecordService)
    ], OutCollectionImport.prototype, "importOthersDataExcelRecordService", void 0);
    __decorate([
        State
    ], OutCollectionImport.prototype, "principalList", void 0);
    __decorate([
        Emit("onClickCaseDetail")
    ], OutCollectionImport.prototype, "viewDetail", null);
    OutCollectionImport = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                ImportVisitFollowCase: ImportVisitFollowCase,
            },
        })
    ], OutCollectionImport);
    return OutCollectionImport;
}(Vue));
export default OutCollectionImport;
